<!-- 选择微信扫描二维码出来后的页面 -->
<template>
  <div class="Reconciliation"   v-loading="loading">
    <div class="container">
      <div class="form">
        <div class="cell">
          <div class="label">客 户</div>
          <div class="content">
            {{ entity.CustomerName }}
          </div>
        </div>
        <div class="cell">
          <div class="label">电 话</div>
          <div class="content">
            {{ entity.CustomerPhone ? entity.CustomerPhone : "" }}
          </div>
        </div>
        <div class="cell">
          <div class="label">地 址</div>
          <div class="content">
            {{ entity.CustomerAddress ? entity.CustomerAddress : "" }}
          </div>
        </div>
        <div class="cell">
          <div class="label">账 期</div>
          <div class="content">{{ entity.StartTime + ' ~ ' + entity.EndTime }}</div>
        </div>
        <div class="cell">
          <div class="label">上期余额</div>
          <div class="content cRight">
            {{ toThousands(entity.Balance) }}
          </div>
        </div>
        <div class="cell">
          <div class="label">本期新欠</div>
          <div class="content cRight">
            {{ toThousands(entity.Arrears) }}
          </div>
        </div>
        <div class="cell">
          <div class="label">本期还款</div>
          <div class="content cRight">
            {{ toThousands(entity.Repayment) }}
          </div>
        </div>
        <div class="cell">
          <div class="label">本期余额</div>
          <div class="content cRight">
            {{ toThousands(entity.Residue) }}
          </div>
        </div>
      </div>
      <div class="table">
        <div class="tr">
          <div class="th td">日期</div>
          <!-- <div class="th td action">类型</div> -->
          <div class="th td">金额</div>
          <div class="th td">欠款余额</div>
          <div class="th td action">操作</div>
        </div>
        <div class="tr" v-if="data.length > 0">
          <div class="td">初期欠款</div>
          <!-- <div class="td action"></div> -->
          <div class="td"></div>
          <div class="td">{{toThousands(data[0].PreviousOwing)}}</div>
          <div class="td action"></div>
        </div>
        <div
          v-for="item in data"
          :key="item.Id"
          :class="item.Type == 3 ? 'warn tr' : 'tr'"
        >
          <div class="td">
            {{ item.CreateTime.substring(0, 10) }}
          </div>
          <!-- <div class="td action">
            {{
              item.Type == 0
                ? "还款"
                : item.Type == 1
                ? "赊货"
                : item.Type == 2
                ? "现收"
                : "作废 "
            }}
          </div> -->
          <!-- <div class="td action">
                    {{item.Type==0?'还款':item.Type==1?'赊货':'作废'}}
                </div> -->
          <div class="td">
            {{ toThousands(item.Money) }}
          </div>
          <div class="td">
            {{ toThousands(item.Owing) }}
          </div>
          <div class="td action">
            <span
              style="color: blue"
              v-if="item.Type != 0"
              @click="toWebView(item.BillId, item.billlistId)"
              >详情</span
            >
          </div>
        </div>
      </div>
      <!--  v-if="show" -->
    </div>
    <div
      v-if="show" style="dispaly: flex;
          justify-content: flex-end;
          margin-top: 10px;
          text-align: right;
          position: fixed;
          padding-bottom:30px;
          background-color:#fff;
          bottom:0;
          width:95%
        "
      >
       
      <a :href="href" style="color: #fff;">
        <el-button class="fixBtn" type="primary" style="width:100%">
          去确认
        </el-button>
      </a>
       
    </div>
    <div style="height: 50px;"></div>
    <!-- 小票页面组件 -->
    <ReceiptPage ref="receiptPage" :parentObj="{ ...this }" />
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
import { getMonthRange, toThousands } from "../../utils/Tools";
// 引入小票页面
import ReceiptPage from "../Overdue/Receipt.vue";
export default {
  components: {
    ReceiptPage,
  },
  props: {
    parentObj: { type: Object },
  },
  data() {
    return {
      href: "",
      loading:false,
      toThousands,
      entity: {},
      data: [],
      queryParams: {},
      show: false,
    };
  },
  async created() {
    let queryParams
    if(this.$route.query.id){
     await this.$http
        .post("/BO/BIllcollection/GetDzVluae?id="+this.$route.query.id, {}).then(res=>{
          queryParams = res.Data
        })
    }else if (this.$route.query.data){
      queryParams = JSON.parse(this.$route.query.data);
    }
    console.log(this.$route.query.data);
    console.log(queryParams);
    this.show = this.$route.query.show;
    this.queryParams = queryParams;
    console.log(
      "'获取微信扫描二维码过来的参数'this.$route.query",
      this.$route.query
    );
    // 赋值去确认的href
    this.getHrefRequest(queryParams);
    // 获取欠款对账单
    await this.getReconciliationData();


    
    // if(this.show==true) return
    await this.getShareInfo();
  },

  methods: {
    getHrefRequest() {
      // 处理传递过来的参数  解决在打开微信小程序页面的参数不传递问题
      let object = this.queryParams;
      let arr = [];
      for (const key in object) {
        arr.push(key + ":" + object[key]);
      }
      let str = arr.join(",");
      console.log("获取最终的段联系地址", str);
      this.$http
        .post("/BO/BillList/GetRecLink", {
          id: str, //二维码内容
          AppType:'AppShq'
        })
        .then((result) => {
          if (result.Success) {
            this.href = result.Data; //获取短链接
            console.log("是否获取到href", this.href, result);
          }
        });
    },
    // 去小票页面
    toWebView(Id, billId) {
      // url: '/pages/Arrears/webview?Id=' + Id + '&billId=' + billId
      // 打开小票页面
      this.$refs.receiptPage.openForm(Id, true);
    },
    // 获取欠款对账单
    async getReconciliationData() {
      this.loading=true
      await this.$http
        .post("/BO/BIllcollection/GetReconciliationData", {
          CompanyId: this.queryParams.CompanyId,
          CustomerId: this.queryParams.CustomerId,
          StartTime: this.queryParams.StartTime,
          EndTime: this.queryParams.EndTime,
          BillConfirmId: this.queryParams.BillConfirmId,
        })
        .then((res) => {
          this.loading=false
          if (!res.Success) {
            this.$message.error(res.Ms);
            this.entity = {};
            this.data = [];
            return;
          }
          console.log("数据", res);
          this.entity = res.Data;
          this.entity.StartTime = this.entity.StartTime?( this.entity.StartTime).replace('0:00:00', ''):''
          this.entity.EndTime = this.entity.EndTime?( this.entity.EndTime).replace('0:00:00', ''):''
          this.data = res.Data.BIllcollectionList;
        });
    },
    // 获取微信认证
    async getShareInfo() {
      let StartTime = this.entity.StartTime
        ? this.entity.StartTime.split("/").join("-").substring(0, 10)
        : "";
      let endTime = this.entity.EndTime
        ? this.entity.EndTime.split("/").join("-").substring(0, 10)
        : "";
      let url = location.href.split("#")[0]; // 只需要传地址就好，其他后端都会写好来
      let ticketData = await this.$http.get(
        "/BO/BillList/GetWxSign?AppType=AppShq&url=" + url
      );
      let arr = ticketData.Data.split(",");
      const config = {
        // appId: "wx2ba9027e237ff635",
        appId: "wx2ba9027e237ff635",
        timestamp: arr[2],
        nonceStr: arr[1],
        signature: arr[0],
      };
      wx.config({
        debug: false, //是否打开debug调试模式。
        appId: config.appId, // appID 公众号的唯一标识
        timestamp: config.timestamp, // 生成签名的时间戳
        nonceStr: config.nonceStr, //  生成签名的随机串
        signature: config.signature, // 生成的签名
        jsApiList: ["onMenuShareAppMessage"],
      });
      wx.ready(() => {
        var shareData = {
          title: "对账函",
          desc:
            this.entity.CustomerName +
            "  [" +
            StartTime +
            " ~ " +
            endTime +
            "对账单]",
          link: location.href + "&show=true",
          imgUrl: "https://hzq.yoojet.com/wx.png",
          success: function (res) {
            console.log("分享好友成功");
          },
          cancel: function (err) {
            console.log(err, "错误信息");
          },
        };
        //点击要去分享
        wx.onMenuShareAppMessage(shareData);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.warn {
  background-color: #ff9999;
}

.fixedBtn {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 9;
}

.Reconciliation {
  padding: 10px;
  color: #000;
  background-image: url("/static/background.png");
  background-size: cover;
  box-sizing: border-box;
  overflow-y: scroll;

  .imgBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 180px;
    overflow: hidden;
    width: 100%;
    background-color: #fff;

    .img {
      transform: rotate(-90deg);
      width: 160px;
      background-color: #f5f5f5;
    }
  }

  .container {
    width: 100%;
    background-color: #fff;

    .title {
      font-weight: bold;
      text-align: center;
    }

    .form {
      margin: 10px 0;
      border-bottom: 1px solid #ccc;
      line-height: 2.5;

      .cell {
        border: 1px solid #ccc;
        border-bottom: none;
        display: flex;
        .label {
          width: 90px;
          border-right: 1px solid #ccc;
          padding-right: 20px;
          text-align: right;
          background-color: #fafafa;
        }

        .content {
          flex: 1;
          padding: 0 20px;
        }

        .cRight {
          text-align: right;
        }
      }
    }

    .table {
      border-bottom: 1px solid #ccc;

      .tr {
        border: 1px solid #ccc;
        border-bottom: none;
        display: flex;
        align-items: center;
        height: 100%;

        .td {
          padding: 10px 10px;
          height: 100%;
          // border-right: 1px solid #ccc;
          flex: 1;
          text-align: center;
          font-size: 12px;
        }

        .th {
          font-weight: bold;
          line-height: 2;
          font-size: 15px;
        }

        .action {
          flex: inherit;
          width: 70px;

          text {
            color: blue;
          }
        }
      }
    }
  }

  .confirm {
    position: fixed;
    right: 30px;
    bottom: 60px;
  }
}
</style>